import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'bgoInitials',
  pure: true,
})
export class InitialsPipe implements PipeTransform {
  transform(item: {firstName?: string; lastName?: string}): string {
    if (item.firstName && item.lastName) {
      return `${item.firstName.charAt(0)}${item.lastName.charAt(0)}`
    } else if (item.firstName && !item.lastName) {
      return item.firstName.charAt(0)
    } else if (!item.firstName && item.lastName) {
      return item.lastName.charAt(0)
    } else {
      return ''
    }
  }
}

import {Pipe, PipeTransform} from '@angular/core'
import {isToday, isYesterday, format, parseISO} from 'date-fns'

@Pipe({
  name: 'bgoRelativeDate',
  pure: true,
})
export class RelativeDatePipe implements PipeTransform {
  transform(
    dateOrIsoString: Date | string | null | undefined,
    dateProperties: {defaultDateFormat: string; today: string; yesterday: string},
  ): string | undefined {
    if (!dateOrIsoString) return undefined

    const date = dateOrIsoString instanceof Date ? dateOrIsoString : parseISO(dateOrIsoString)

    if (isToday(date)) {
      return dateProperties.today
    }

    if (isYesterday(date)) {
      return dateProperties.yesterday
    }

    return format(date, dateProperties.defaultDateFormat)
  }
}

import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'bgoNullToEmptyString',
  pure: true,
})
export class NullToEmptyStringPipe implements PipeTransform {
  transform(nullish: string | null | undefined): string {
    if (nullish === null) {
      return ''
    }

    if (typeof nullish === 'undefined') {
      return ''
    }

    return nullish
  }
}
